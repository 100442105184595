/* Palette */
:root {
  --red: #cf230a;
  --cyan: #00bece;
  --veryDarkGrey: #3e4f60;
  --darkGrey: #516375;
  --quiteGrey: #707070;
  --middleGrey: #787979;
  --grey: #c0c1c2;
  --lightGrey: #f2f3f4;
  --white: #ffffff;
}

body {
  font-family: "Titillium Web", sans-serif;
  color: var(--darkGrey);
}

.is-active-menu-item > div > .menu-item {
  color: var(--cyan) !important;
}

.is-active-side-menu-item > :first-child {
  color: var(--cyan) !important;
}

.garzone-loader {
  fill: white;
  animation: draw 2s linear infinite alternate;
}

.ant-list-item-meta-title {
  margin: 0;
}

.radius > .ant-image-img {
  border-radius: 4px;
}

.backToChats {
  display: none;
}

.logoMobile {
  display: none;
}
.logo {
  display: initial;
}

.rbc-event-label {
  color: var(--grey);
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none !important;
}

.rbc-events-container {
  margin: 0 !important;
}

@keyframes draw {
  to {
    fill: #23b7c9;
  }
}

@-webkit-keyframes draw {
  to {
    fill: #23b7c9;
  }
}

@media (max-width: 1142px) {
  /* Menu */
  .topHeader,
  .imageBottomHeaderContainer,
  .labelMenuBottomHeader {
    display: none;
  }
  .titleBottomHeader {
    font-size: 22px !important;
  }
  /* card negozio nel component search input */
  .imageCardNegozioSearch {
    display: none;
  }
  .chatList {
    left: 0 !important;
    width: 100% !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .chatPanel {
    display: none;
  }
  .chatDetails {
    display: none;
    width: 100% !important;
    background-color: white !important;
  }
  .backToChats {
    display: block;
  }
}

@media (max-width: 512px) {
  .logoMobile {
    display: initial;
  }
  .logo {
    display: none;
  }
}
